import GLightbox from "glightbox";

// get load more button element and gallery iamges which are hidden
const LOAD_MORE_BTN = document.querySelector("#gallery-load-more");
let galleryImages = document.querySelectorAll(".gallery-image[data-show='hide']");

// check if there are any images to load initially
buttonVisablity();

//add listener to the button for click events
LOAD_MORE_BTN.addEventListener("click", () => {
	//checking how many hidden images there are, if there are less then 8 loop on reamining number of images
	if (galleryImages.length < 8) {
		for (let i = 0; i < galleryImages.length; i++) {
			galleryImages[i].dataset.show = "show";
		}
	} else {
		for (let i = 0; i < 8; i++) {
			galleryImages[i].dataset.show = "show";
		}
	}

	//reset galleryImages to the new number of hidden images
	galleryImages = document.querySelectorAll(".gallery-image[data-show='hide']");

	// if there are no images left to load, hide button
	buttonVisablity();
});

function buttonVisablity() {
	if (galleryImages.length <= 0) {
		LOAD_MORE_BTN.style.display = "none";
	}
}

//setup lighbox for gallery
const lightbox = GLightbox({
	touchNavigation: true,
	loop: true,
	autoplayVideos: true,
});
